@use "sass:map";

/* TYPOGRAPHY */

$font-primary: "Roboto Custom", sans-serif;
$font-weights: ("regular": 400, "medium": 500, "bold": 700);
$font-sizes: (
  h1: 24px,
  h2: 20px,
  h3: 18px,
  h4: 16px,
  h5: 14px,
  button_m: 16px,
  button_s: 14px,
  body_l: 16px,
  body_m: 14px,
  cuption_m: 13px,
  cuption_s: 12px
);
$line-heights: (
  h1: 28px,
  h2: 24px,
  h3: 20px,
  h4: 18px,
  h5: 18px,
  button_m: 20px,
  button_s: 16px,
  body_l: 24px,
  body_m: 20px,
  cuption_m: 16px,
  cuption_s: 16px
);

@mixin text-format($title, $color, $weight: "regular") {
  font-family: $font-primary;
  font-size: map.get($font-sizes, $title);
  line-height: map.get($line-heights, $title);
  font-weight: map.get($font-weights, $weight);
  color: $color;
}
