/* MIXINS */

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}
@mixin laptop {
  @media (min-width: 992px) and (max-width: 1279px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin margin-reset {
  h1,h2,h3,h4,h5,h6,p,ul,li,label,textarea {
    margin: 0;
    padding: 0;
  }
}
