/* FONTS */

/* Open Sans */
@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans-Bold.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans-BoldItalic.ttf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto Custom';
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Custom';
  src: url('../assets/fonts/Roboto/Roboto-Bold.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Custom';
  src: url('../assets/fonts/Roboto/Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Custom';
  src: url('../assets/fonts/Roboto/Roboto-BoldItalic.ttf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto Custom';
  src: url('../assets/fonts/Roboto/Roboto-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto Custom';
  src: url('../assets/fonts/Roboto/Roboto-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

